import { AMaterialLessonBlockTemplate } from "../../models";

const text = {
  title: {
    sample: "this is a text title",
    size: "61px",
    color: "var(--primary)",
  },
  subtitle: {
    sample: "this is a text subtitle",
    size: "14px",
    color: "var(--accent)",
  },
  desc: {
    sample: "this is a text description",
    size: "16px",
    color: "black",
  },
};

export const Texts: AMaterialLessonBlockTemplate[] = [
  // Text 1:
  {
    name: "Text Content 1",
    template: {
      name: " Text Content 1",
      groups: [
        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "100%",
            height: "100%",
          },
        },
      ],
      style: {
        width: "80%",
        margin: "auto",
        height: "100%",
        padding: "25px",
      },
    },
  },

  {
    name: "Text Content 2",
    template: {
      name: "Text Content 2",
      groups: [
        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                color: text.desc.color,
                fontSize: "27px",
                fontWeight: "bold",
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "100%",
            height: "100%",
          },
        },
      ],
      style: {
        width: "80%",
        margin: "auto",
        height: "100%",
        padding: "25px",
      },
    },
  },

  {
    name: "Text Content 3",
    template: {
      name: " Text Content 3",
      groups: [
        {
          items: [
            {
              type: "text",
              value: text.subtitle.sample,
              style: {
                width: "100%",
                color: text.subtitle.color,
                fontSize: text.subtitle.size,
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "100%",
            height: "100%",
          },
        },
      ],
      style: {
        width: "80%",
        margin: "auto",
        height: "100%",
        padding: "25px",
      },
    },
  },

  {
    name: "Text Content 4",
    template: {
      name: " Text Content 4",
      groups: [
        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "100%",
            height: "100%",
          },
        },

        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "100%",
            height: "100%",
          },
        },
      ],
      style: {
        width: "80%",
        margin: "auto",
        height: "100%",
        // padding: "25px",
        display: "flex",
        justifyContent: "center",
        gap: "20px",
      },
    },
  },

  {
    name: "Text Content 5",
    template: {
      name: " Text Content 5",
      groups: [
        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                color: text.desc.color,
                fontSize: "27px",
                height: "fit-content",
                fontWeight: "bold",
              },
            },
          ],
          style: {
            width: "100%",
            height: "100%",
          },
        },

        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "100%",
            height: "100%",
          },
        },
      ],
      style: {
        width: "80%",
        margin: "auto",
        height: "100%",
        // padding: "25px",
        display: "flex",
        justifyContent: "center",
        gap: "20px",
      },
    },
  },
  {
    name: "Text Content 6",
    template: {
      name: " Text Content 6",
      groups: [
        {
          items: [
            {
              type: "text",
              value: text.subtitle.sample,
              style: {
                width: "100%",
                color: text.subtitle.color,
                fontSize: text.subtitle.size,
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "100%",
            height: "100%",
          },
        },

        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "100%",
            height: "100%",
          },
        },
      ],
      style: {
        width: "80%",
        margin: "auto",
        height: "100%",
        // padding: "25px",
        display: "flex",
        justifyContent: "center",
        gap: "23px",
      },
    },
  },

  {
    name: "Text Content 7",
    template: {
      name: " Text Content 7",
      groups: [
        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "100%",
            height: "100%",
          },
        },

        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "100%",
            height: "100%",
          },
        },
        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "100%",
            height: "100%",
          },
        },
      ],
      style: {
        width: "90%",
        margin: "auto",
        height: "100%",
        // padding: "25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
      },
    },
  },
  {
    name: "Text Content 8",
    template: {
      name: " Text Content 8",
      groups: [
        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                color: text.desc.color,
                fontSize: "27px",
                height: "fit-content",
                fontWeight: "bold",
              },
            },
          ],
          style: {
            width: "100%",
            height: "100%",
          },
        },

        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "100%",
            height: "100%",
          },
        },
        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "100%",
            height: "100%",
          },
        },
      ],
      style: {
        width: "90%",
        margin: "auto",
        height: "100%",
        // padding: "25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
      },
    },
  },

  {
    name: "Text Content 9",
    template: {
      name: " Text Content 9",
      groups: [
        {
          items: [
            {
              type: "text",
              value: text.subtitle.sample,
              style: {
                width: "100%",
                color: text.subtitle.color,
                fontSize: text.subtitle.size,
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "100%",
            height: "100%",
          },
        },

        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "100%",
            height: "100%",
          },
        },
        {
          items: [
            {
              type: "text",
              value: text.desc.sample,
              style: {
                width: "100%",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",
              },
            },
          ],
          style: {
            width: "100%",
            height: "100%",
          },
        },
      ],
      style: {
        width: "90%",
        margin: "auto",
        height: "100%",
        // padding: "25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
      },
    },
  },
];
